/* eslint-disable quotes */
export const promotionsTypes = {
  NATIONAL: "national",
  LISTING: "listing",
  ACCOUNT: "account",
  GENERAL: "general",
};

export const NOTIFICATION = {
  title: "Love's truck care",
  icon: "Notification",
  description:
    "100 FYX (TRAC Interstar) listings are due to renewal on June 1,2020 - Email",
  linkTitle: "View todo",
  link: "/listings/439909?toDoId=123",
  notification: "You were added as a participant on this Todo:",
};

export const USER_TYPE = {
  FLEET_CARRIER: 1,
  OWNER_OPERATOR: 2,
  SERVICE_VENDOR: 3,
  COMPANY_DRIVER: 4,
  OTHER: 5,
};

export const USER_TYPES_NAMED = {
  1: "Fleet",
  2: "Owner operator",
  3: "Service vendor",
  4: "Driver",
  5: "Other",
};

export const activities = {
  changeTodoType: `<span>{{admin_name}} changed todo type from <span class="fts-badge-inline">{{from_type}}</span> to <span class="fts-badge-inline">{{to_type}}</span></span>`,
  callLog: `<p class="mb-0">{{admin_name}} CALLED <span class="contacted-person">{{contacted_persons}}</span></p>`,
  voidInvoice: `<p class="mb-0">{{admin_name}} VOIDED invoice #<span class="voided-invoice">{{invoiceId}}</span></p>`,
  emailLog: `<p class="mb-0">{{admin_name}} SENT AN E-MAIL TO <span class="contacted-person">{{emails}}</span></p>`,
  convertAccount: `<p class="mb-0">{{admin_name}} LINKED this account, to account <a href="{{href}}" class="account-link">{{toAccount}}</a></p>`,
  convertListing: `<p class="mb-0">{{admin_name}} REASSIGNED this listing from account <a href="{{fromHref}}" class="account-link">{{fromAccount}}</a> to account <a href="{{toHref}}" class="account-link">{{toAccount}}</a></p>`,
  removeListing: `<p class="mb-0">{{admin_name}} DELETED listing ID #{{listingId}}</p>`,
  removeAccount: `<p class="mb-0">{{admin_name}} DELETE this account </p>`,
  enableAccount: `<p class="mb-0">{{admin_name}} ENABLE this account </p>`,
  enableListing: `<p class="mb-0">{{admin_name}} ENABLE this listing </p>`,
  assignedAccountUser: `<p class="mb-0">{{admin_name}} ASSIGNED this account to {{assigned_admin}}</p>`,
  unassignedAccountUser: `<p class="mb-0">{{admin_name}} UN-ASSIGNED this account from {{assigned_admin}}</p>`,
  ////////////////////////
  // upgradeToBasic: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  // upgradeToBasicPlus: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  // upgradeToStandard: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  // upgradeToPremium: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Premium</p>`,
  upgradeToPayingListing: `<p class="mb-0">{{admin_name}} UPGRADED #{{listingId}} from free to paying listing</p>`,
  upgrade_listing_to_basic: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  upgrade_listing_to_basic_plus: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  upgrade_listing_to_standard: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  upgrade_listing_to_premium: `<p class="mb-0">{{admin_name}} UPGRADE listing #{{listing_id}} from {{oldType}} to Premium</p>`,

  ////////////////////////
  revertListingUpdate: `<p class="mb-0">{{admin_name}} REVERTED listing update to previous state </p>`,
  revertAccountUpdate: `<p class="mb-0">{{admin_name}} REVERTED account update to previous state </p>`,
  ////////////////////////
  // downgradeToFree: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Free</p>`,
  // downgradeToBasic: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  // downgradeToBasicPlus: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  // downgradeToStandard: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  downgrade_listing_to_free: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Free</p>`,
  downgrade_listing_to_basic: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic</p>`,
  downgrade_listing_to_basic_plus: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Basic Plus</p>`,
  downgrade_listing_to_standard: `<p class="mb-0">{{admin_name}} DOWNGRADE listing #{{listing_id}} from {{oldType}} to Standard</p>`,
  createPayingListing: `<p class="mb-0">{{admin_name}} added a new paid listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  createFreeListing: `<p class="mb-0">{{admin_name}} added a new free listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  ////////////////////////
  createAccount: `<p class="mb-0">{{admin_name}} created account for {{companyName}} in {{city}}, {{state}}{{phonePart}}</p>`,
  updateAccount: `<p class="mb-0">{{admin_name}} updated the account.</p>`,
  createAccountTodo: `<p class="mb-0">{{admin_name}} created an <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{todoType}}</span> account todo</p>`,
  createAccountNote: `<p class="mb-0">{{admin_name}} posted a <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> account note</p>`,
  createStickyNote: `<p class="mb-0">{{admin_name}} posted a <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> sticky note</p>`,
  postAccountNoteReply: `<p class="mb-0">{{admin_name}} posted a reply to an account note.</p>`,
  pinTodoNoteToAccount: `<p class="mb-0">{{admin_name}} pinned a todo note to the account.</p>`,
  createAccountPromotion: `<p class="mb-0">{{admin_name}} created an account promotion: {{promotionName}}.</p>`,
  uploadAccountFile: `<p class="mb-0">{{admin_name}} uploaded an account file.</p>`,
  createAltContact: `<p class="mb-0">{{admin_name}} added {{firstName}} {{lastName}} as an alt contact.</p>`,
  removeAltContact: `<p class="mb-0">{{admin_name}} removed an old alt contact for {{firstName}} {{lastName}}.</p>`,
  setAccountMerchant: `<p class="mb-0">{{admin_name}} set this account as a merhcant.</p>`,
  unsetAccountMerchant: `<p class="mb-0">{{admin_name}} removed this account from merhcant.</p>`,
  setAccountCorporate: `<p class="mb-0">{{admin_name}} set this account as a corporate.</p>`,
  convertToSubAccount: `<p class="mb-0">{{admin_name}} set this account as a sub account of {{companyName}} in {{city}}, {{state}} account#{{accountId}}.</p>`,
  deleteAccount: `<p class="mb-0">{{admin_name}} DELETED account ID #{{accountId}}.</p>`,
  sendRequestPasswordChange: `<p class="mb-0">{{admin_name}} Send request password change to <b>{{email}}</b>, account id: <b>#{{accountId}}</b>.</p>`,
  /////////////////////////
  createListing: `<p class="mb-0">{{admin_name}} created listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  updateListing: `<p class="mb-0">{{admin_name}} updated listing #{{listingId}} for {{companyName}} in {{city}}, {{state}}.</p>`,
  approvedRequest: `<p class="mb-0">{{admin_name}} approved an update request for listing ID {{listingId}}, for {{companyName}} in {{city}}, {{state}}.</p>`,
  rejectedRequest: `<p class="mb-0">{{admin_name}} rejected an update request for listing ID {{listingId}}, for {{companyName}} in {{city}}, {{state}}..</p>`,
  // Change activity log to "Faruk Avdic" approved update request.

  createListingTodo: `<p class="mb-0">{{admin_name}} created a new <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{todoType}}</span> todo for {{listingId}} in {{city}}, {{state}}.</p>`,
  postedListingNote: `<p class="mb-0">{{admin_name}} posted a new <span class=" small-note-label badge badge-secondary rounded-pill" style="background-color: rgb(178, 178, 179);" >{{noteType}}</span> note for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  createListingPromotion: `<p class="mb-0">{{admin_name}} created a new promotion "{{promotionName}}" for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  emailListingProof: `<p class="mb-0">{{admin_name}} emailed a proof for listing #{{listingId}} in {{city}}, {{state}} to {{emailAddress}}.</p>`,
  uploadListingFile: `<p class="mb-0">{{admin_name}} uploaded a new file for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  collectPayment: `<p style="word-break: break-word;" class="mb-0">{{admin_name}} collected a new payment with source #{{sourceId}} in amount of \${{amount}}.</p>`,
  setListingAuthorized: `<p class="mb-0">{{admin_name}} set listing #{{listingId}} in {{city}}, {{state}} as an authorized location.</p>`,
  setListingFeatured: `<p class="mb-0">{{admin_name}} added listing #{{listingId}} in {{city}}, {{state}} to featured authorized location.</p>`,
  unListingAuthorized: `<p class="mb-0">{{admin_name}} removed listing #{{listingId}} in {{city}}, {{state}} from a featured authorized location.</p>`,
  recievePayment: `<p class="mb-0">\${{paymentAmount}} payment recieved for listing #{{listingId}} in {{city}}, {{state}}.</p>`,
  duplicateOnce: `<p class="mb-0">{{admin_name}} duplicated listing #{{listingId}} in {{city}}, {{state}} once.</p>`,
  duplicateMultiple: `<p class="mb-0">{{admin_name}} duplicated listing #{{listingId}} and created listings ID {{listingsIds}}</p>`,
  reassignListing: `<p class="mb-0">{{admin_name}} reassigned listing #{{listingId}} from account <a href="{{href}}" class="account-link">{{fromAccount}}</a> to this account.</p>`,
  bulkListingUpdate: `<p class="mb-0">{{admin_name}} updated listing #{{listingId}} and applied changes to listing IDs: {{listingIDs}}.</p>`,
  bulkListingUpdateIndividual: `<p class="mb-0">{{admin_name}} updated listing #{{listingId}} as part of bulk update from listing {{mainListingId}}.</p>`,
  //////////////////////////
  associateTodo: `<p class="mb-0">{{admin_name}} associated todo with listing {{listingName}}.</p>`,
  updateTodoField: `<p class="mb-0">{{admin_name}} updated {{todoField}} from {{oldFieldValue}} to {{newFieldValue}}.</p>`,
  uploadTodoFIle: `<p class="mb-0">{{admin_name}} uploaded a new file {{fileName}} on a todo.</p>`,
  archiveTodo: `<p class="mb-0">{{admin_name}} archived todo #{{todoId}}</p>`,
  addTodoPerticipant: `<p class="mb-0">{{admin_name}} added a {{participantName}} {{participantLastname}} as a participant on a todo #{{todoId}}</p>`,
  addTodoPerticipants: `<p class="mb-0">{{admin_name}} added a {{participantsNames}} as participants on a todo #{{todoId}}</p>`,
  unassignUserFromTodo: `<p class="mb-0">{{admin_name}} unassigned {{userName}} {{userLastname}}from a todo #{{todoId}}.</p>`,
  unassignUsersFromTodo: `<p class="mb-0">{{admin_name}} unassigned {{userNames}} from a todo #{{todoId}}.</p>`,
  reassignedTodo: `<p class="mb-0">{{admin_name}} reassigned todo #{{todoId}} to {{firstName}} {{lastName}}.</p>`,
  addTodoCalendar: `<p class="mb-0">{{admin_name}} added {{todoName}} to a calendar.</p>`,
  unarchiveTodo: `<p class="mb-0">{{admin_name}} unarchived {{todoName}}.</p>`,
  updatedTodo: `<p class="mb-0">{{admin_name}} updated todo #{{todoId}}.</p>`,
  //////////////////////////
  updatePromotion: `<p class="mb-0">{{admin_name}} updated {{oldPromotionFields}} on a promotion #{{promotionId}} to {{newPromotionFields}}.</p>`,
  duplicatePromotion: `<p class="mb-0">{{admin_name}} duplicated the #{{promotionId}} and created promotion id {{promotionIds}}.</p>`,
  promotionExpired: `<p class="mb-0">{{promotionName}} promotion expired.</p>`,
  applyGeneralPromotion: `<p class="mb-0">{{admin_name}} applied a general promotion {{promotionName}} to {{generalPromotionType}} #{{generalPromotionTypeId}}.</p>`,
  //////////////////////////
  updateRequest: `<p class="mb-0">{{userName}} {{userLastname}} sent an update request for a listing #{{listingId}}.</p>`,
  createInvoice: `<p class="mb-0">An invoice #{{invoiceId}} was created for a listing #{{listingId}}.</p>`,

  changeInvoiceStatus: `<p class="mb-0">Invoice status changed for invoice  #{{invoiceId}} , listing #{{listingId}}.Changed from {{oldInvoiceStatus}} to {{newInvoiceStatus}}.</p>`,
  sendInvoice: `<p class="mb-0">Invoice #{{invoiceId}} was sent to {{recipientEmail}}.</p>`,
  ///////////////////////////
  processPayment: `<p class="mb-0">Payment #{{paymentId}} was processed in the amount of \${{amount}}.</p>`,
  paymentUnsuccesful: `<p class="mb-0">Payment for invoice #{{invoiceId}} in the amount of \${{amount}} was unsuccessful.</p>`,
  emailSent: `<p class="mb-0">{{emailType}} was sent to {{recipientEmail}}.</p>`,
  requestParticipation: `<p class="mb-0">{{admin_name}} requested participation for todo #{{todoName}}.</p>`,
  handleParticipationRequest: `<p class="mb-0">{{admin_name}} {{status}} participation for todo #{{todoId}}.</p>`,

  generatedAccountReport: `<p class="mb-0">{{admin_name}} generated an account report.</p>`,
  generatedListingReport: `<p class="mb-0">{{admin_name}} generated an listing report.</p>`,
  accountReportByEmail: `<p class="mb-0">{{admin_name}} emailed an account report to: {{emails}}</p>`,
  accountReportBySMS: `<p class="mb-0">{{admin_name}} sent a account report by SMS to: {{phoneNumbers}}</p>`,
  listingReportByEmail: `<p class="mb-0">{{admin_name}} emailed an listing report to: {{emails}}</p>`,
  listingReportBySMS: `<p class="mb-0">{{admin_name}} sent a listing report by SMS to: {{phoneNumbers}}</p>`,
  visibleToVendor: `<p class="mb-0">{{admin_name}} made report visible to vendor.</p>`,
  deletedReport: `<p class="mb-0">{{admin_name}} deleted report {{reportName}}.</p>`,
  extendedValidity: `<p class="mb-0">{{admin_name}} extended the validity of report {{reportName}}. New validity date: {{newDate}}</p>`,

  sendReport: `<p class="mb-0">{{admin_name}} shared a report.</p>`,
  sentProof: `<p class="mb-0">{{admin_name}} sent a listing proof to {{proofRecievers}}.</p>`,
  sentMultipleProofs: `<p class="mb-0">{{admin_name}} sent multiple listing proofs to {{proofsRecievers}}. Listing proofs sent for listing ID's: {{listingIds}} .</p>`,
  approvedProof: `<p class="mb-0">{{admin_name}} approved a listing proof #{{proofId}}.</p>`,
  proofRevised: `<p class="mb-0">{{admin_name}} changed proof status from rejected to pending for proof #{{proofId}}.</p>`,
  canceledListingTypeChange: `<p class="mb-0">{{admin_name}} canceled {{changeType}} and voided an invoice with ID: #{{invoiceId}}.Reason: {{voidReason}}.</p>`,
  listingOpenedFromUpgradesListingActivity: `<p class="mb-0">{{admin_name}} opened this listing from the Global Upgrades List.</p>`,
  listingOpenedFromUpgradesAccountActivity: `<p class="mb-0">{{admin_name}} opened listing, {{listing_name}}, from the Global Upgrades List.</p>`,
  listingOpenedFromNewListingsListingActivity: `<p class="mb-0">{{admin_name}} opened this listing from the Global Listings List.</p>`,
  listingOpenedFromNewListingsAccountActivity: `<p class="mb-0">{{admin_name}} opened listing, {{listing_name}}, from the Global Listings List.</p>`,
  newAccountOpened: `<p class="mb-0">{{admin_name}} opened account, {{account_id}}, from the new accounts list.</p>`,

  sendPaymentLink: `<p class="mb-0">{{admin_name}} shared payment link for invoice(s): {{invoiceId}} with {{numbers}}.</p>`,
  sendReceiptLink: `<p class="mb-0">{{admin_name}} shared receipt link for invoice: #{{invoiceId}} with {{numbers}}.</p>`,
  cancelledRecurringPayment: `<p class="mb-0">{{admin_name}} cancelled recurring payment for listing #{{adId}}.</p>`,
  accountAccessLogin: `<p class="mb-0">{{admin_name}} logged in via remote access.</p>`,
  accessRequestCreate: `<p class="mb-0">{{admin_name}} created remote access request.</p>`,
  remoteAccessDisable: `<p class="mb-0">{{admin_name}} disabled account access.</p>`,
};

export const LISTING_TYPE = {
  PREMIUM: 1,
  STANDARD: 2,
  BASIC_PLUS: 3,
  BASIC: 4,
  FREE: 5,
};

export const LISTING_IMAGE_SIZES = {
  1: { width: 295, height: 240 },
  2: { width: 343, height: 130 },
  3: { width: 343, height: 65 },
};

export const USER_TYPE_COLOR = {
  1: "#94C7D9",
  2: "#EB9797",
  3: "#94C7D9",
  4: "#BDA3DF",
  5: "#B1BABD",
};

export const USER_TYPE_ICON = {
  1: "FleetCarrier",
  2: "OwnerOperator",
  3: "ServiceVendor",
  4: "CompanyDriver",
  5: "OtherType",
};

export const USER_STATUSES = {
  0: "Disabled",
  1: "Active",
  3: "Deactivated",
};

export const USER_TYPE_NAMES = {
  1: "Fleet/Carrier",
  2: "Owner Operator",
  3: "Service Vendor",
  4: "Company Driver",
  5: "Others",
};

export const PERMISSION_GATE_PRINCIPLE = {
  ALL: "all",
};

export const PERMISSION_GATE_DEFAULT_FALLBACK = () => null;
export const PERMISSIONS = {
  GLOBAL_TAB: 1,
  LISTING_TAB: 2,
  ACCOUNT_TAB: 3,
  ADDITIONAL_FEATURES: 4,
};

export const SUB_PERMISSIONS = {
  [PERMISSIONS.GLOBAL_TAB]: {
    SEARCH: 1,
    DASHBOARD: 2,
    CREATE_ACCOUNT: 3,
    PROMOTIONS: 4,
    ACTIVITIES: 5,
    UPDATES: 6,
    RENEWALS: 7,
    SALES: 8,
    INVOICES: 9,
    PAYMENTS: 10,
    COMMUNICATIONS: 11,
    TODOS: 12,
    META: 13,
    MANAGEMENT: 14,
  },
  [PERMISSIONS.LISTING_TAB]: {
    LISTING_DETAILS: 15,
    PROMOTIONS: 16,
    INVOICES: 17,
    PAYMENTS: 18,
    CALL_LOGS: 19,
    LISTING_REPORTS: 20,
    TODOS: 21,
    NOTES: 22,
    FILES: 23,
    ACTIVITY_HISTORY: 24,
    SENT_PROOFS: 51,
  },
  [PERMISSIONS.ACCOUNT_TAB]: {
    ACCOUNT: 25,
    LISTINGS: 26,
    INVOICES: 27,
    PAYMENTS: 28,
    PROMOTIONS: 29,
    REPORTING: 30,
    COMMUNICATIONS: 31,
    TODOS: 32,
    NOTES: 33,
    FILES: 34,
    ACTIVITY_HISTORY: 35,
    USERS: 36,
    SENT_PROOFS: 50,
  },
  [PERMISSIONS.ADDITIONAL_FEATURES]: {
    CORPORATE_TOGGLE: 37,
    APPLY_TO_MULTIPLE_LISTING: 38,
    RENEWAL_EMAILS_TOGGLE: 39,
    AUTOMATIC_DOWNGRADES_TOGGLE: 40,
    MERCHANT_TOGGLE: 41,
    AUTHORIZED_TOGGLE: 42,
    FEATURED_TOGGLE: 43,
    REMOVE_REPORT: 44,
    ACCESS_TO_FREE_ACCOUNTS: 45,
    ACCESS_TO_LEAD_ACCOUNTS: 46,
    ACCESS_TO_CUSTOMER_ACCOUNTS: 47,
    ACCESS_TO_CLOSED_ACCOUNTS: 48,
    ACCESS_TO_OTHER_ACCOUNTS: 49,
    REMOVE_ACCOUNT: 52,
    REMOVE_LISTING: 53,
    "MULTIPLE_REASSIGN_FOR_LEADS/CUSTOMERS": 54,
    ACCESS_ALL_USERS_ON_SALES_TAB: 55,
    ACCESS_ALL_ACCOUNT_OWNERS_ON_RENEWALS_TAB: 56,
    DELETE_NOTE: 57,
    ACCOUNT_REPORT_CUSTOM_DATE_RANGE: 71,
    LISTING_REPORT_CUSTOM_DATE_RANGE: 72,
    GENERATE_ACCOUNT_REPORT_CUSTOM_DATE_RANGE: 73,
    GENERATE_LISTING_REPORT_CUSTOM_DATE_RANGE: 74,
    PAY_WITH_EXISTING_CARD: 78,
    REMOVE_EXISTING_CARD: 79,
    REQUEST_PASSWORD_CHANGE: 80,
    VIEW_CALS_ON_REPORT: 81,
    GENERATE_REPORT_WITH_CALLS: 82,
    CHANGE_RENEWAL_DATE: 83,
    GET_TOTAL_SEARCHES: 84,
  },
};

export const REQUIRED_ERROR_MESSAGE = "Required";
